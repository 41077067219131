import React, { useState, useEffect } from "react"
import Navbar from "./navbar"
import "../css/main.css"
import API from "../services/api"
import { getUser, setUser } from "../services/auth"

const Layout = ({ children, classes }) => {
  const [userRole, setUserRole] = useState(null)
  const userInfo = getUser()
  const getUserRole = async () => {
    const { data } = await API.post("sce/get-user/roles", {
      userId: userInfo.user.databaseId,
    })
    setUserRole(data)
    setUser({ ...userInfo, userRole: data })
  }

  useEffect(() => {
    getUserRole()
  }, [])

  return (
    <>
      <Navbar />
      {children}
    </>
  )
}

export default Layout
