import React from "react"
import * as AiIcons from "react-icons/ai"
import * as IoIcons from "react-icons/io"
import * as FiIcons from "react-icons/fi"


const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "navText",
  },
  {
    title: "GamiPress",
    path: "/gamipress",
    icon: <IoIcons.IoIosPaper />,
    cName: "navText",
  },
  {
    title: "Upload Cobie Data",
    path: "/upload-csv",
    icon: <FiIcons.FiUpload />,
    cName: "navText",
  },
  {
    title: "Create DFX Model",
    path: "/dfx-form",
    icon: <FiIcons.FiBook />,
    cName: "navText",
  },
  {
    title: "PDF Processor",
    path: "/pdf-processor",
    icon: <AiIcons.AiFillControl />,
    cName: "navText",
  },
]

export default SidebarData
